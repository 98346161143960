<template>
  <div class="about-container">
    <div>
      <Typography variant="pc-title-48 mobile-title-32" :text="globalSettings.marketName"></Typography>
      <Typography variant="pc-body-24 mobile-body-14 company-description">
        {{ globalSettings.marketDescription }}
      </Typography>
      <MarketSocials />
    </div>
    <div class="mint-legacy">
      <Typography variant="pc-title-48 mobile-title-32" text="Drops"></Typography>

      <div class="legacy-counts">
        <Count name="Artists" :value="globalData.collectionGroups || '-'"></Count>
        <Count name="Series" :value="globalData.collections || '-'"></Count>
        <Count name="Pieces" :value="globalData.types || '-'"></Count>
        <Count
          name="Minted"
          :value="`${globalClaimsData.totalClaimed || '-'}`"
        ></Count>
      </div>
    </div>
      <div>
      <Typography variant="pc-title-48 mobile-title-32" text="FAQ"></Typography>
      <div class="questions-container">
        <Question v-for="(faq, index) in faqs" :key="faq.order + faq.q" :question="faq.q" :index="index">
          <template v-slot:answer v-if="faq.a">
            <div v-html="faq.a" />
          </template>
        </Question>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, collection, getDoc, getDocs } from 'firebase/firestore'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Count from '../components/about/Count.vue'
import Question from '../components/about/Question.vue'

export default {
  async mounted () {
    this.claimDataCollectionsListener()
    this.claimsMetadataListener()
    const global = await getDoc(doc(this.$fb.db, 'market-data', 'global'))
    this.globalData = global.data()
    // Query globalSettings faq document by order
    const faqs = await getDocs(collection(this.$fb.db, 'market-settings', 'global', 'faq'))
    this.faqs = faqs.docs.map(doc => doc.data())
    this.faqs.sort((a, b) => a.order - b.order)

    if (this.$route.query.modal === 'moonPayUnsupported') {
      this.setGlobalState({ target: 'showingMoonPayUnsupported', val: true })
    }
  },
  data () {
    return {
      globalData: {},
      faqs: []
    }
  },
  computed: {
    ...mapGetters([
      'globalSettings',
      'claimDataCollections',
      'claimsMetadata'
    ]),
    globalClaimsData () {
      return this.claimDataCollections.global || {}
    },
    totalClaims () {
      if (!this.claimsMetadata) return '-'
      let total = 0
      for (const type in this.claimsMetadata) {
        total += this.claimsMetadata[type]
      }
      return total
    }
  },
  components: {
    Count,
    Question
  },
  methods: {
    ...mapActions([
      'claimDataCollectionsListener',
      'claimsMetadataListener'
    ]),
    ...mapMutations([
      'setGlobalState'
    ])
  }
}
</script>

<style lang="scss" scoped>
.about-container {
  margin: 50px 20px;

  @include desktop {
    margin: 0 0 20%;
  }
}

.company-description {
  padding: 20px 0;
}

.mint-legacy {
  margin: 60px 0;
}

.legacy-counts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 3fr;
  gap: 20px;
  margin: 20px 0;

  @media (max-width: $bp-desktop-s) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 17px 8px;

    & > *:last-child {
      grid-column: 1 / -1;
    }
  }
}

.questions-container {
  & > * {
    margin-top: 20px;
  }
}
</style>
