<template>
  <div class="question-container" @click="toggle">
    <Typography class="pc-body-16 mobile-body-14 caps bold">{{
      question
    }}</Typography>
    <v-expand-transition>
      <Typography v-if="hasAnswerSlot && isExpanded" class="pc-sub-16 mobile-body-14 answer">
        <slot name="answer"></slot>
      </Typography>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'Question',

  mounted () {
    if (this.index === 0) {
      this.isExpanded = true
    }
  },
  data () {
    return { isExpanded: false }
  },
  props: {
    question: String,
    index: Number
  },
  computed: {
    hasAnswerSlot () {
      return !!this.$slots.answer
    }
  },
  methods: {
    toggle () {
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style lang="scss" scoped>
.question-container {
  border: 1px solid #ffffff;
  padding: 30px;
  cursor: pointer;

  @include desktop {
    padding: 40px;
  }
}

.answer {
  padding-top: 8px;
}

</style>
